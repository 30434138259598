import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Link from 'next/link'
import moment from 'moment'

import {t} from '@lib/helpers'

const Footer = ({}) => {
  return (
    <div className='Footer'>
      <div className='container'>
        <div className='Footer__tl'>
          <p className='Footer__p'>{t('layout.Footer.tl.p')}</p>

          <a
            className='Footer__a'
            href={`mailto:${t('layout.Footer.tl.email')}`}
          >
            {t('layout.Footer.tl.email')}
          </a>

          <a className='Footer__tel' href='tel:020245560'>
            {t('layout.Footer.tl.tel')}
          </a>
        </div>

        <div className='Footer__tr'>
          <p className='Footer__p'>{t('layout.Footer.tr.p')}</p>

          <Link href='/careers'>
            <a
              className='Footer__a'
              dangerouslySetInnerHTML={{__html: t('layout.Footer.tr.a')}}
            />
          </Link>

          <div className='Footer__tr__imgs-group'>
            <img src='/images/iso-ism.svg' alt='ISO27001' loading='lazy' />
            <img src='/images/iso-pim.svg' alt='ISO27701' loading='lazy' />
          </div>
        </div>

        <div className='Footer__bl'>
          <div className='Footer__links'>
            {[
              {
                label: t('layout.nav.links.3'),
                href: '/about',
              },
              {
                label: t('layout.nav.links.0'),
                href: '/services',
              },
              {
                label: t('layout.nav.links.1'),
                href: '/cases',
              },
              {
                label: t('layout.nav.links.8'),
                href: '/policy',
              },
              {
                label: t('layout.nav.links.9'),
                href: '/security',
              },
              {
                label: t('layout.nav.links.6'),
                href: '/careers',
              },
              {
                label: t('layout.nav.links.5'),
                href: '/contact',
              },
            ].map((x, i) => (
              <Link href={get(x, 'href')} key={i}>
                <a className='Footer__links__a'>{get(x, 'label')}</a>
              </Link>
            ))}

            <div className='Footer__links__socials'>
              <a
                className='Footer__links__social'
                href='https://www.facebook.com/datawowai/'
                target='_blank'
                rel='nofollow noopener noreferrer'
              />

              <a
                className='Footer__links__social Footer__links__social--linkedin'
                href='https://www.linkedin.com/company/datawowio/'
                target='_blank'
                rel='nofollow noopener noreferrer'
              />
            </div>
          </div>
        </div>

        <div className='Footer__br'>
          <p className='Footer__p'>
            &copy; {moment().year()} Data Wow Co., Ltd.
          </p>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {}

export default Footer
